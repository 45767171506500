<template>
    <div class="huds-container" v-if="showResult">  <!-- <div class="huds-container" v-if="starting && vitalSign && showResult"></div> -->
    <div class="row gx-0 mb-5 justify-content-between mx-5">
       
        <signal-quality-box v-if="healthResult?.scanParameters" :scanParameters="healthResult.scanParameters" />
        <wellness
            v-if="holistic?.generalWellness"
            :data="holistic.generalWellness"
            :userType="userType"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        />
    </div>

    <div class="row gx-0 mb-5 justify-content-between mx-5">
        <heart-rate :data="vs?.heartRate" class="col-2" />
        <facial-skin-age v-if="vs?.facialSkinAge" :data="vs.facialSkinAge" class="col-2" /> 
    </div>

    <div class="row gx-0 my-5 justify-content-between mx-5">
        <o2-saturation :data="vs?.spo2" class="col-2" />
        <respiratory-rate
            v-if="vs?.respiratoryRate"
            :data="vs.respiratoryRate"
            :userType="userType"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        /> 
    </div>

    <div class="row gx-0 my-5 justify-content-between mx-5">
        <hrv-sdnn
            v-if="vs?.hrvSdnn" 
            :data="vs.hrvSdnn"
            :userType="userType"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        /> 
        <stress-rating
            v-if="vs?.stress"
            :data="vs.stress"
            :userType="userType"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        /> 
    </div>

    <div class="row gx-0 mt-5 justify-content-between mx-5">
        <blood-pressure
            v-if="vs?.bloodPressure"
            :data="vs.bloodPressure"
            :userType="userType"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        /> 
    </div>
</div>
</template>

<script>
import { defineComponent } from 'vue';

import Logger from "../../common/logger.js";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import adapter from "webrtc-adapter";
import { b2cUserManager as newUserManager } from "vitals-database-plugin";

import SignalQualityBox from "./signal-quality-box-floating.vue";

import HeartRate from "./vital-signs/heart-rate.vue";
import RespiratoryRate from "./vital-signs/respiratory-rate.vue";
import O2Saturation from "./vital-signs/o2-saturation.vue";
import StressRating from "./vital-signs/stress-rating.vue";
import FacialSkinAge from "./vital-signs/facial-skin-age.vue";
import HrvSdnn from "./vital-signs/hrv-sdnn.vue";
import Wellness from "./vital-signs/wellness.vue";
import BloodPressure from "./vital-signs/blood-pressure.vue";

export default defineComponent( {
setup() {
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser
    ? newUserManager.currentUser
    : null;

    if (!currentUser || !newUserManager.currentUser.userId) {
        router.push("/");
    }

return {
    t,
    locale,
    router,
    currentUser,
    // user: ref(currentUser),
    // scanParameters: ref(scanParameters),
    // showTutorial: ref(false),
}
    
},
emits: ['hideResult'],
props: {
    healthResult: {
        // type: Object as PropType<Health>,
        default: null,
    },
    showResult: Boolean,
    userType: String,
},

components: {
    HeartRate,
    RespiratoryRate,
    O2Saturation,
    StressRating,
    FacialSkinAge,
    HrvSdnn,
    BloodPressure,
    Wellness,
    SignalQualityBox,
},
computed: {
    vs() { //  vs():VitalSign | undefined {
        return this.healthResult?.vitalSigns;
    },
    holistic() { //  holistic():HolisticAnalysis | undefined {
        return this.healthResult?.holisticHealth;
    },
    // cardio():CardiovascularRisks | undefined {
    //     return this.healthResult?.risks?.cardiovascularRisks;
    // },
    // covid():CovidRisk | undefined {
    //     return this.healthResult?.risks?.covidRisk;
    // },

    gap() {
        Logger.log(
            `%ccurrent Browswer: [${adapter.browserDetails.browser}]`,
            "color: #DDDDDD"
        );
        if (adapter.browserDetails.browser == "safari") {
            return "20px";
        } else {
            return 0;
        }
    },
},
methods: {
    hideResult() {
        this.$emit('hideResult');
    },
}
})
</script>

<style scoped>
.huds-container {
  position: absolute;
  top: 15%;
  width: calc(100% - 87px); 
  height: 80%;
  overflow-y: scroll;
  /* height: fit-content; */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.huds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}

@media (max-width: 1044px) {
.huds-container {
  position: absolute;
  top: auto;
  left: 87px;
  bottom: 200px;
  width: calc(100% - 87px);
  height: 15%;
  overflow-y: scroll;
  overflow-x: scroll;
  display: flex;
  /* justify-content: center; */
  gap: v-bind(gap);
}
.row {
  justify-content: flex-start !important;
  flex-wrap: nowrap;
  margin: auto 2px !important;
}
}

@media (max-width: 768px) {
#icon-mobile {
  display: inline;
  position: absolute;
  width: 35px;
  height: auto;
  left: 50vw;
  top: 4vh;
}
.huds-container {
  width: 100%;
  left: 0;
}
}

@media (max-width: 700px) {
.huds-container {
  justify-content: flex-start;
  height: 20%;
  bottom: 170px;
  /* height: fit-content; */
}
}

</style>