
<template>
  <DebugConsole v-if="debugMode"/>
  <web-login v-if="!host.match(/^localhost:[\d]{1,4}/)" />
  <router-view v-if="auth || host.match(/^localhost:[\d]{1,4}/)" />
  
</template>

<script>
import { ref, provide } from "vue";
import WebLogin from "./views/login/web-login.vue";
import DebugConsole from "./common/debug-console.vue";
import Logger from './common/logger';
import { VERSION } from "./version";

export default {
  name: "main-app",
  setup() {
    const idle = ref(false);
    const auth = ref(window.sessionStorage.auth);
    function updateAuth() {
      auth.value = true;
      window.sessionStorage.auth = true;
    }
    function updateIdle() {
      idle.value = !idle.value;
    }
    provide("idle", { idle, updateIdle });
    provide("auth", { auth, updateAuth });

    return {
      auth,
      idle,
      currentUser: undefined
    };
  },
  data() {
    let debugMode = false
    let appVersion = VERSION
    let appTitle = `Vitals - Personal ${appVersion}`

    return {
      vitalSignObject: undefined,
      healthObject: undefined,
      host: location.host,
      appVersion: appVersion,
      appTitle: appTitle,
      debugMode: debugMode,
      debugMessage: "[ Your debug message ]",
      doneLoading: {
        faceDetectionView: false,
        system: true,
        scanning: true
      }
    };
  },
  mounted() {
    if (this.debugMode) {
      Logger.log(`%c< ${this.appTitle} > DEBUG MODE`, "color: red")
    } else {
      Logger.log(`< ${this.appTitle} >`)
    }
  },
  components: {
    WebLogin,
    DebugConsole,
  },
};
</script>