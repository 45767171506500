<template>
    <div class="instruction-text">
        Please keep your face in the frame
    </div>
    <div :style="containerStyle">
        <img src="./assets/silh_with_neck.png" class="overlay" alt="">
        <!-- <div class='bboxStyle'/> -->
    </div>
</template>

<script>
export default {
    name: 'bounding-box-view',
    props: {'result': Object},  /* 'aspectRatio': Number */
    setup() {

    },

    watch: {
        result() {
            this.videoWidth = this.$parent.$refs.videoContainer.clientWidth;
            this.videoHeight = this.$parent.$refs.videoContainer.clientHeight;
            this.videoOffsetTop = this.$parent.$refs.videoContainer.offsetTop;
            this.videoOffsetLeft = this.$parent.$refs.videoContainer.offsetLeft;
           
            this.aspectRatio = this.videoWidth / this.videoHeight;

            this.additionalWidth = this.aspectRatio * this.videoHeight - this.videoWidth;
            this.additionalHeight = this.videoWidth / this.aspectRatio - this.videoHeight;
            if(this.additionalWidth < 0) {
                this.additionalWidth = 0;
            }
            else if (this.additionalHeight < 0) {
                this.additionalHeight = 0;
            }

            // console.log("videoWidth: ", this.videoWidth, "videoHeight: ", this.videoHeight, "offsetTop: ", this.videoOffsetTop, "offsetLeft: ", this.videoOffsetLeft, "additionalWidth: ", this.additionalWidth, "additionalHeight: ", this.additionalHeight, "aspectRatio: ", this.aspectRatio);

            // mediapipe
            // this.width = val.faceBox.width * (this.videoWidth+this.additionalWidth);
            // this.height = val.faceBox.height * (this.videoHeight+this.additionalHeight);
            // this.left = this.videoWidth * (val.faceBox.xCenter) - (this.width) / 2.0;
            // this.top = this.videoHeight * val.faceBox.yCenter - this.height / 2.0;

            // face-api
            // this.width = val.faceBox.width * this.videoWidth;
            // this.height = val.faceBox.height * this.videoHeight;
            // this.left = (val.faceBox.xCenter - val.faceBox.width / 2) * this.videoWidth;
            // this.top = (val.faceBox.yCenter - val.faceBox.height / 2) * this.videoHeight;
            // }
        },
    },
    computed: {
        containerStyle() {
            const style = {
                position: 'absolute',
                left: '0px',
                top: this.videoOffsetTop + 'px',
                width:this.videoWidth + 'px',
                height:this.videoHeight + 'px', 
                transform: "scaleX(-1)",
                overflow: 'hidden',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }
            // console.log("containerStyle: ", style, "left: ", this.left, "top: ", this.top, "width: ", this.width, "height: ", this.height, "videoWidth: ", this.videoWidth, "videoHeight: ", this.videoHeight, "additionalWidth: ", this.additionalWidth, "additionalHeight: ", this.additionalHeight, "aspectRatio: ", this.aspectRatio)
            return style
        },
        
        // dynamic bounding box
        bboxStyle() {
            const style = {
                position:"absolute", 
                borderStyle: "solid", 
                borderWidth: "8px",
                borderColor: "#198754",
                left: this.left + 'px', 
                top: this.top + 'px', 
                width: this.width + 'px',
                height: this.height + 'px',
                "z-index": 2,
            }
            return style;
        }

        // static bounding box
        // bboxStyle() {
        //     const style = {
        //         position:"absolute", 
        //         borderStyle: "solid", 
        //         borderWidth: "8px",
        //         borderColor: "#198754",
        //         borderBottomLeftRadius: "100% 100%",
        //         borderBottomRightRadius: "100% 100%",
        //         borderTopLeftRadius: "100% 100%",
        //         borderTopRightRadius: "100% 100%",
        //         // left: this.videoWidth/2.8 + 'px', 
        //         // top: this.videoHeight/6.5 + 'px', 
        //         transform: "translate(0, -40px)",    
        //         width: 350+ 'px', 
        //         height: 450  + 'px', 
        //     }
        //     return style;
        // }
    },
    data() {
        return{
            width: 0,
            height: 0,
            left: 0,
            top: 0,
            videoWidth: 0,
            videoHeight:0,
            videoOffsetTop: 0,
            videoOffsetLeft: 0,
            additionalWidth: 0,
            additionalHeight: 0,
            aspectRatio: 0,
        }
    }
}
</script>

<style scoped>
.instruction-text {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 8%;
    font-size: 30px;
    color: white;
    text-shadow: 0px 0px 5px black;
    z-index: 3;
    text-align: center;
    pointer-events: none;
}

.bboxStyle {
    position: absolute;
    border-style: solid;
    border-width: 8px;
    border-color: #198754;
    border-bottom-left-radius: 100% 100%;
    border-bottom-right-radius: 100% 100%;
    border-top-left-radius: 100% 100%;
    border-top-right-radius: 100% 100%;
    transform: translate(0, -65px);
    width: 400px;
    height: 500px;
}

.overlay {
    object-fit: cover;
    width: inherit; /* 100vw */
    height: inherit; /* 100vh */
    transform: scale(1.2) translate(0, -20px); 
}

@media (max-width: 480px) {
    .overlay {
        transform: scale(0.9);
    }
    .instruction-text {
        font-size: 13px;
        top: 12%;
    }
}

@media (max-width: 1040px) {
    .bboxStyle {
        width: 350px;
        height: 450px;
    }
    
    .overlay {
        transform: scale(1.1) translate(0px, -50px);
    } 
}

@media (min-width: 2560px) {
    .overlay {
        transform: scale(1)
    }    
}
</style>