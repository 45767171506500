<template>
    <!-- <BIBOCircle
    :style="{width: radius*1.5, height: radius*1.5, 'border-radius': radius}"
    :state="state"
    :radius="radius"/> -->

    <div id="get-vs-button-container" >
        <button ref="button"
            v-if="(healthResult?.stage === 3) && !error && !showResult"
            :disabled="disabled"
            id="get-vs-button"
            class="btn btn-success"
            @click="startScanning">{{ t('button.start') }}
        </button>

        <button ref="button" v-if="(healthResult?.health && healthResult?.stage === 3 && showResult) || error" id="get-vs-button"
            class="btn btn-light p-0"
            @click="rescan">
            <div class="rescan-button justify-content-center align-items-center mb-2">
                <img src="./assets/restart.svg" class="rescan-image mx-auto" alt="">
                <div class="rescan-text w-100">
                    SCAN AGAIN
                </div>
            </div>
        </button>
   
        <circular-progress
            v-if="(healthResult?.stage === 1 || healthResult?.stage === 2) && !healthResult.health"
            :radius="radius"
            :progress="progress"
            :stroke="10"
            :startBreathing="true"
            :text="timeLeft + 's'" />
    </div>
</template>

<script>
import { ref } from 'vue';
import CircularProgress from './circular-progress.vue';
import { useI18n } from 'vue-i18n';
// import BIBOCircle from './breathing-circle.vue'
// import InformationBox from "./information-box.vue"

export default {
    name: 'vital-sign-button-new',
    emits: ['startScanning', 'rescan'], 
    props: {
        healthResult: {
            type: Object
        },
        disabled:  {
            type: Boolean,
            default: false
        },
        radius: Number,
        timeLeft: {type: Number, default: null},
        showResult: Boolean,
        error: Boolean,
        // enable: Object,
        // conditions: Object,
    },
    setup() {
        const { t, locale } = useI18n()

        return {
            t, locale,
            vitalSign: ref(),
            // state: ref('idle'),
            progress: ref(0)
        }
    },
    data() {
        return {
            startBreathing: false,
            // showResult: false,
            // disabled: false,
        }
    },
    watch: {
        healthResult(newValue) {
            // console.log('healthResult:', newValue);
        if (newValue) {
            // console.log('healthResult.stage:', newValue.stage);
        }
        },
        timeLeft(newValue, oldValue) {
            if(oldValue == null && newValue !== null) {
                this.startBreathing = true;
            }
            if (newValue === null) {
                this.progress = 0;
                // this.state = "idle";
                this.startBreathing = false;
            } else { 
                if (this.healthResult?.stage === 1) {
                    this.progress = Math.round(((this.totalTime - Number(this.timeLeft)) / this.totalTime) * 100.0)
                } else if (this.healthResult?.stage === 2) {
                    this.progress = 100;
                }
            }
        },
    },
    created() {
        this.totalTime = 25;
    },
    methods: {
        startScanning() {
            this.$emit('startScanning')
        },
        rescan() {
            this.$emit("rescan")
        }
    },
    components: {
        CircularProgress,
        // BIBOCircle,
        // InformationBox
    }
}
</script>

<style scoped>
#get-vs-button-container {
  position: absolute;
  left: 0; /* 87px; */
  width: 100%; /* calc(100% - 87px); */
  bottom: 64px;
  display: flex;
  justify-content: center;
}

#get-vs-button {
    opacity: 1.0;
    width: 128px;
    height:128px;
    border-radius: 128px;
}
.btn:disabled{
  background-color: #555555; /* Gray */
  border: none;
}
.btn-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding: 10px 16px;
    font-size: 16px;
    text-align: center;
}

.rescan-button{
    position: relative;
}

@media (max-width: 768px) {
    #get-vs-button-container {
        position: absolute;
        left: 0px;
        width: 100%;
        bottom: 95px;
        display: flex;
        justify-content: center;
    }

    .rescan-image {
        width: 40%;
    }

    .rescan-text {
        font-size: 13px;
    }

    #get-vs-button {
        opacity: 1.0;
        width: 96px;
        height: 96px;
        border-radius: 96px;
    }
}

</style>
