<template>
  <LoadingScreen
    v-if="!initialized
    || (healthResult?.stage === 2 && healthResult?.health === undefined)"
    :initialized="initialized"
    :healthResult="healthResult" />

  <HomePageOnboard />
  <!-- <nav ref="nav" class="navbar fixed-top navbar-light"> -->
  <!--
    <div class="video" id="standby-screen" v-if="sysStatus=='standby'">
      <p v-if="!starting" class="system-message text-center">{{ message || t('message.startup')}}</p>
      <p v-if="starting" class="system-message text-center">{{ message || t('message.loading')}}</p>
    </div>
    -->

  <img id="logo" src="./assets/panoptic_ai.svg" />

  <!-- button navigation -->
  <div class="buttons">
    <!-- <img class="quick-help-button btn navbar-btn mx-2 my-4"
      src="./assets/help_icon.svg"
      v-on:click="showGuide"/> -->
    <!-- <div
      class="btn navbar-btn mx-2 my-4 disableBox"
      data-bs-toggle="modal"
      data-bs-target="#quickTutorialModal"
    > -->
    <div
      class="btn navbar-btn mx-2 my-4 power-up"
      data-bs-toggle="modal"
      data-bs-target="#quickTutorialModal"
    >
      <img class="quick-help-button" src="./assets/help_icon.svg" />
    </div>

    <!-- Start button -->
    <button
      class="btn btn-success navbar-btn mx-2 my-4 mx-lg-0 button-rounded power-up"
      id="power-on-button"
      v-show="!isActive"
      :disabled="isActive"
      @click="startSystem()"
    >
      <span
        v-show="isActive"
        class="spinner-border spinner-border-sm button-spinner2"
        role="status"
        aria-hidden="true"
      ></span>
      {{ t("button.power_on") }}
    </button>

    <!-- Stop button -->
    <button
      class="btn btn-danger navbar-btn mx-2 my-4 mx-md-0 button-rounded shut-down"
      v-show="isActive"
      :disabled="!isActive || healthResult?.stage === 0 || healthResult?.stage === 1 || healthResult?.stage === 2"
      v-on:click="stopSystem()"
    >
      <span
        v-show="!isActive && healthResult?.stage === 3"
        class="spinner-border spinner-border-sm button-spinner2"
        role="status"
        aria-hidden="true"
      ></span>
      {{ t("button.shutdown") }}
    </button>
   
    <!-- Full screen button -->
    <!-- <button
      class="btn btn-light navbar-btn mx-2 my-4 full-screen"
      v-on:click="fullScreen()"
    >
      {{ t("button.full_screen") }}
    </button> -->
  </div>
  <!-- </nav> -->

    <!-- popup tutorial page -->
    <scan-tutorial />

  <div id="video-container" ref="videoContainer">
    <div id="webcamContainer" ref="videoContainer">
    
      <VitalSignCamera
        ref="vscam"
        :device="selectedDevice"
        class="camera"
        :isActive="isActive"
        :userInfo="userInfo"
        :config="config"
        :visualizationOptions="visualizationOptions"
        @onVideoFrameProcessed="onVideoFrameProcessed"
        @onCameraDevicesUpdated="onCameraDevicesUpdated"
        @onInitialized="onInitialized"
        @onError="onError"
      />

      <bounding-box-view
        v-if="healthResult?.stage === 3 && !showResult"
        :result="facebox"
      /> 

      <information-box
        v-if="isActive"
        :enable="(healthResult?.stage === 1 || healthResult?.stage === 2) && !healthResult.health"
      ></information-box>

      <condition-checklist v-if="isActive && conditions !== undefined && healthResult?.stage === 3 && !error && !showResult" :conditions="conditions" />
      <!-- <img v-if="debugCamera" class="debugFace" ref="facePhoto" /> need? -->

        <!-- <div v-if="simulation && sysStatus=='running'">
        <img class="video" src="./assets/dummy_user.png">
        <p v-if="message" class="running-message">{{message}}</p>
      </div>
      <div v-if="!simulation && sysStatus=='running'">
        <video-feed ref="video" class="video" feed-id="CAMERA" :enableReloadTimer="false"/>
        <p v-if="message" class="running-message">{{message}}</p>
      </div>
          <div class="huds-container" v-if="!conditions && vitalSign && vitalSign.spo2 && !stopping">
      -->

      <div v-if="error && isActive && faceLost" class="instruction-text">
          <p class="w-75">
            Face Lost!
            <br />
            Please scan again and keep your face inside the camera
          </p>
      </div>

      <health-result-view v-if="healthResult?.health && isActive && showResult" :healthResult="healthResult?.health" :userType="userType" :showResult="showResult" @hideResult="hideResult"/>

      <div>
      <vital-sign-button
          v-if="healthResult && isActive"
          class="vsButton info-bar"
          :healthResult="healthResult"
          :disabled="!disabled"
          :radius="64"
          :timeLeft="number()"
          :showResult="showResult"
          @startScanning="startScanning"
          @rescan="rescan"
          :error="error"
        />

      <div class="w-30 camera-switch" >
          <div
            class="dropup"
            v-if="devices  && healthResult?.stage === 3"
          >

            <button
              class="btn camera-button dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="./assets/switch-camera.svg" alt="" />
            </button>
            <ul
              class="dropdown-menu"
            >
              <li v-for="device in devices" :key="device.id">
                <div
                  :class="`dropdown-item ${
                    selectedDevice == device.id ? 'selected-camera' : ''
                  }`"
                  @click="() => switchCamera(device.id)"
                >
                  {{ device.label }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  <div class="copyright text-center" v-if="isActive">
    <p>{{ t("copyright") }}</p>
  </div>
</div>

  <side-bar />
</template>

<script>
import { defineComponent } from 'vue';
import { GetHealthStage, VitalSignCamera } from "vue-vital-sign-camera";
import { ServerId } from "vue-vital-sign-camera";
import ConditionChecklist from "./ConditionChecklist.vue";
import HealthResultView from "./HealthResultView.vue";

import Logger from "../../common/logger.js";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import VitalSignButton  from './vital-sign-button-js.vue';
import SideBar from "../sidebar/sidebar.vue";
import HomePageOnboard from "../onboarding/home-page-onboard.vue";
import adapter from "webrtc-adapter";
import ScanTutorial from "./scan-tutorial.vue";
import { b2cUserManager as newUserManager } from "vitals-database-plugin";
import LoadingScreen from "./loading-screen.vue";

import InformationBox from "./information-box.vue";
import BoundingBoxView from "./bounding-box-view.vue";

import { SystemState } from "./SystemState.ts";

export default defineComponent( {
  props: {
    simulation: Boolean,
  },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser
      ? newUserManager.currentUser
      : null;
    let vitalSign = null;
    if (currentUser && newUserManager.currentUser.userId) {
      vitalSign = currentUser.lastVitalSigns;
    } else {
      router.push("/");
    }
    // if (!currentUser || !newUserManager.currentUser.userId) {
    //   router.push("/");
    // }

    return {
      t,
      locale,
      router,
      showTutorial: ref(false),
      currentUser,
      vitalSign: ref(vitalSign),
      // user: ref(currentUser) 
      // message: ref(),
      // scanParameters: ref(scanParameters),
    }
  },
  async created() {
    await this.getUserType();
    this.updateConfig(); 
  },
  watch: {
    finishedScanning(newVal) {
      if (newVal) {

      /* Save the result */
      this.$root.$data.healthObject = this.healthResult?.health;
      newUserManager.currentUser.setHealth(this.healthResult?.health);
      // console.log("currentUser in finished scanning: " + JSON.stringify(newUserManager.currentUser))
      newUserManager.currentUser.uploadHealth();
      // console.log("userInfo in finished scanning: " + JSON.stringify(this.userInfo))
      this.facialSkinAge = 0;
      this.finishedScanning = false;
      // return;
      }
    },
    healthObject: {
      deep: true,
      handler: function () {
        this.vitalSign = newUserManager.currentUser.lastVitalSigns;
      },
    },
  },
  data() {
    return { 
      isActive: false, // can be used in a button to turn on/off the camera
      healthResult: undefined, // undefined as GetHealthResult|undefined,
      frameSize: undefined, // undefined as VideoFrameInfo|undefined,
      facebox: undefined, // undefined as NormalizedFacebox|undefined,
      conditions: undefined, // undefined as ScanConditions|undefined,
      userInfo: {}, // as UserInfo, 
      // userInfo: { 
        // age: 30,
        // gender: Gender.Male,
        // userId: '07a51b41-e1a4-4570-94a7-04ef371cc4f3',
        // height: 100,
        // weight: 100,
        // waistCircumference: 50,
        // planType: 'free'
      // },
      showResult: false,
      disabled: false,
      config: {
        serverId: ServerId.AwsProd,
        apiKey: 'CeVHP9OdUj54mr8btHU6L51VeDAj69pw91a7l5nP',
      },
      landmarks: undefined, //  undefined as NormalizedLandmarkList|undefined,
      selectedDevice: undefined, // undefined as string | undefined,
      initialized: false,
      boundingBoxEnabled: false,
      faceMeshEnabled: true,
      error: undefined, //  undefined as unknown,

      aspectRatio: undefined,
      userType: undefined,
      devices: [],

      finishedScanning: false,
      hasSetHealthData: false,
      faceLost: false,
      // started: false,

      // started: SystemState.started
    }
  },
  beforeRouteLeave() {
    // console.log("started in beforerouteleave: " + this.started)
    if(this.started) {
      this.isActive = false;
    }
  },
  beforeRouteEnter( to, from, next) {
    next(vm => {
      if (from.path === '/' || from.path === '/input-profile') {
        return
      }
      if (to.path === '/home') {
        // console.log("vm.started in beforerouteenter: " + vm.started)

        if (vm.started) {
        vm.isActive = true;
        } else {
        vm.isActive = false;
        }
      }
    });
  },
  methods: {
    startScanning() {
      const vscam = this.$refs.vscam; // :InstanceType<typeof VitalSignCamera> = this.$refs.vscam as InstanceType<typeof VitalSignCamera>;
      vscam.startScanning();
      this.showResult = true;
      this.vitalSign = undefined;
    },
    onCameraDevicesUpdated(event) {
      this.devices = event.devices
      if (event.selected !== undefined && event.selected !== '') {
        this.selectedDevice = event.selected
      }
    },
    onVideoFrameProcessed(processedVideo) {
      const {videoFrameInfo, facebox, healthResult, scanConditions, landmarks} = processedVideo;
      this.conditions = scanConditions;
      this.healthResult = healthResult;
      this.facebox = facebox;
      this.frameSize = videoFrameInfo;
      this.landmarks = landmarks;
      this.disabled = this.conditions !== undefined
        && this.conditions.centered && this.conditions.distance && this.conditions.serverReady
        && this.conditions.frameRate && this.conditions.lighting && this.conditions.movement;
      if(healthResult?.stage === 3 && healthResult.health !== undefined && !this.hasSetHealthData) {
        this.finishedScanning = true;
        this.hasSetHealthData = true;
      }
    },
    onInitialized() {
      this.initialized = true;
    },
    onError(error) { // onError(error:unknown) {
      this.error = error;
      this.faceLost = error.message && error.message.includes("face lost");
      if (!this.faceLost) {
        throw error;
      }
    },
    clearError() {
      this.error = undefined;
    },
    hideResult() {
      this.showResult = false;
      this.healthResult && (this.healthResult.health = undefined);
    },
    updateConfig() {
      const currentUser = newUserManager.currentUser;
      const getAge = function (dateString) {
        var ageInMilliseconds = new Date() - new Date(dateString);
        return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
      };

      var userSettings = currentUser?.userSettings;
      // let userInfo = {};
      if (userSettings == null) {
        return;
      }
      Object.assign(this.userInfo, userSettings);
      this.userInfo.age = userSettings.birthday ? getAge(userSettings.birthday) : 25;
      delete this.userInfo.name;
      delete this.userInfo.email;
      delete this.userInfo.phoneNumber;
      delete this.userInfo.profileImage;

      // match the plugin format for gender
      // if (this.userInfo.gender == "male") {
      //   this.userInfo.gender = 0;
      // } else {
      //   this.userInfo.gender = 1;
      // }

      // this.config = {
      //   resolution: [
      //     vitalsConfig.cameraParameters.videoWidth,
      //     vitalsConfig.cameraParameters.videoHeight,
      //   ],
      //   userInfo: userInfo,
      // };
      
      // console.log("userInfo in updateConfig: " + JSON.stringify(this.userInfo))
    },
    // async removeProfile() {
    //     await newUserManager.currentUser.currentUser.clearUserSettings();
    //     this.form = newUserManager.currentUser.currentUser.userSettings;
    //     this.updateProfileFilled(false);
    //     this.vitalSign = null;
    //     this.scanParameters = null;
    //     this.failedScan = false;
    // },
    switchCamera(deviceId) {
      this.selectedDevice = deviceId;
    },
    async getUserType() {
      setTimeout(() => {
        if (!this.currentUser) return;
        else {
          this.userType = this.currentUser.userSettings.planType;
          // console.log("planType in healthResultView: ", this.userType);
        }
      }, 2000);
    },
    showGuide() {
      this.showTutorial = !this.showTutorial;
    },
    number() { // number():string {
      if(!this.healthResult) {
          return '-1';
      }
      return this.healthResult.remainingTime;
      // return Math.round(this.healtResult.remainingTime.toFixed(0));
    },
    rescan() {
      this.showResult = false;
      this.clearError();
      this.healthResult = undefined;
      // this.conditions = undefined;
      // this.facebox = undefined;
      // this.landmarks = undefined;
      // resetMovementArray();
    },
    startSystem: async function () {
      Logger.info("Start button pressed");
      this.isActive = true;
      this.clearError();
      this.hideResult();
      this.vitalSign = undefined;
      // this.started = true;

      SystemState.started = true;
      // this.healthResult?.stage === 3;
       // this.healthResult = undefined;

      // this.showResult = false;
      // this.updateIdle();
      // this.conditions = undefined;
      // this.starting = true;
      // this.stopping = false;
      // console.log("health result inside startsystem: " + this.healthResult)

    },
    stopSystem: function () {
      Logger.info("Stop button pressed.");
      this.isActive = false;
      this.healthResult = undefined;
      this.vitalSign = undefined;
      // this.started = false;
      SystemState.started = false;
      // stopScanning();
      // this.updateIdle();
      // this.stopping = true;
      // this.starting = false;
    },
    // fullScreen() {
    //   this.$refs.videoContainer.requestFullscreen();
    // },
  },
  computed: {
    started() {
      console.log('SystemState.started:', SystemState.started);

      return SystemState.started;
    },
    visualizationOptions() {
      // console.log("healthResult in visualizationOptions: " +  JSON.stringify(this.healthResult) + " stage: " + this.healthResult?.stage + " showResult: " + this.showResult + " error: " + this.error + " isactive " + this.isActive)
      return {
        faceMesh: {
          color: this.healthResult?.stage === 3 ? 'rgba(211, 211, 211, 0.3)' : 'rgba(144, 238, 144, 0.3)',
          enabled: this.faceMeshEnabled,
          lineWidth: '0.4%',
          animate: this.healthResult?.stage === GetHealthStage.AnalyzingData || this.healthResult?.stage === GetHealthStage.CollectingData,
          // this.healthResult?.stage === 3 || this.healthResult?.stage === 0 || this.healthResult == undefined || this.error ? false : true
          // animate: this.healthResult?.stage === 3 || this.healthResult?.stage === 0 || this.healthResult == undefined || this.error ? false : true,
        },
      }
    },
    // animateFaceMesh() {
    //   if (this.healthResult?.stage === 2 || this.healthResult?.stage === 1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    healthObject() {
      if (this.$root.$data.healthObject !== undefined) {
        // console.log("this.$root.$data.healthObject: ", this.$root.$data.healthObject);
        return "healthObject updated.";
      } else {
        return "no health result";
      }
    },
    gap() {
      Logger.log(
        `%ccurrent Browswer: [${adapter.browserDetails.browser}]`,
        "color: #DDDDDD"
      );
      if (adapter.browserDetails.browser == "safari") {
        return "20px";
      } else {
        return 0;
      }
    },
  },
  components: {
    VitalSignCamera,
    ConditionChecklist,
    HealthResultView,
    SideBar,
    LoadingScreen,
    HomePageOnboard,
    ScanTutorial,
    VitalSignButton,
    InformationBox,
    BoundingBoxView,
  },
  async mounted() {
    // this.isActive = false;
    // this.enumerateDevice();
    // await this.getUserType()
    if (!newUserManager.currentUser) {
      return;
    }
    this.updateConfig(); 
  },
})
</script>

<style>
body {
  background-color: lightgray;
  /* margin: 0; */
  /* overflow: hidden; */
}

#video-container {
  overflow: hidden;
  height: 100vh;
  margin-left: 87px;
  z-index: 0;
}

#webcamContainer {
  /* margin-right:87px; */
  position: relative;
  height: 100vh;
  overflow: hidden;
}

/* wvs: face-detection-view */
video {
  -webkit-transform: v-bind(transform);
  transform: v-bind(transform);
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
</style>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #screen {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
} */

.instruction-text1 {
  position: absolute;
  display: flex; /* block */
  justify-content: center;
  width: 100%;
  top: 8%;
  font-size: 30px;
  color: white;
  text-shadow: 0px 0px 5px black;
  z-index: 3 !important;
  text-align: center;
  pointer-events: none;
}

.quick-help-button {
  /* display: inline-block; */
  opacity: 0.7;
  min-width: 40px;
  min-height: 40px;
  vertical-align: middle;
  z-index: 2;
}
.buttons {
  display: flex;
  position: absolute;
  vertical-align: middle;
  top: 0px;
  right: 10px;
}
.power-up {
  z-index: 1;
}
.shut-down {
  z-index: 4;
}
.button-rounded {
  border-radius: 50px;
  min-width: 120px;
}

.instruction-text {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 87px);
  top: 8%;
  font-size: 30px;
  color: white;
  text-shadow: 0px 0px 5px black;
  z-index: 3;
  text-align: center;
  pointer-events: none;
}

#logo {
  position: absolute;
  width: 300px;
  height: 60px;
  left: 115px;
  top: 13px;
  z-index: 1;
}
.system-message {
  margin-top: 20%;
}
.instruction-box {
  position: absolute;
  top: 10%;
  width: calc(100% - 87px);
  font-size: 48px;
  text-align: center;
  color: #ff3535;
  text-shadow: 1px 1px white;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.running-message {
  margin-top: 20%;
  font-size: 48px;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 5px #000000;
}
.disclaimer {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 20px;
  padding: 10px;
  color: black;
  background-color: lightgrey;
  opacity: 0.8;
  font-size: 0.9em;
  text-align: center;
}
.dropdown-menu {
  cursor: pointer;
}
.vsButton {
  overflow: hidden;
}
.copyright {
  position: relative;
  font-size: 14px;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  /* z-index: 5; */
}
#icon-mobile {
  display: none;
}
.huds-container {
  position: absolute;
  top: 15%;
  width: calc(100% - 87px);
  height: 80%;
  overflow-y: scroll;
  /* height: fit-content; */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.huds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}
.info-bar {
  position: absolute;
  left: calc(87px + 20%);
  width: calc(60% - 87px);
  bottom: 6%;
  display: flex;
  justify-content: space-around;
}
.info-button {
  width: 150px;
  height: 75px;
  min-height: 50px;
  font-size: large;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  margin-bottom: auto;
}

.camera-switch {
  transform: scale(0.8);
  position: absolute;
  bottom: 54px;
  right: 3%;
  /* z-index: 4; */
}

@media (max-width: 1044px) {
  .huds-container {
    position: absolute;
    top: auto;
    left: 87px;
    bottom: 200px;
    width: calc(100% - 87px);
    height: 15%;
    overflow-y: scroll;
    overflow-x: scroll;
    display: flex;
    /* justify-content: center; */
    gap: v-bind(gap);
  }
  .row {
    justify-content: flex-start !important;
    flex-wrap: nowrap;
    margin: auto 2px !important;
  }
  .info-bar {
    position: absolute;
    left: 87px;
    width: calc(100% - 87px);
    bottom: 6%;
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .camera-switch {
    bottom: 80px;
  }
  #video-container {
    margin-left: 0px;
  }
  #logo {
    position: absolute;
    width: 200px;
    height: 30px;
    left: 15px;
    top: 38px;
  }
  #icon-mobile {
    display: inline;
    position: absolute;
    width: 35px;
    height: auto;
    left: 50vw;
    top: 4vh;
  }
  button {
    display: block;
    z-index: 1;
    font-size: 12px;
  }
  .buttons {
    min-width: 150px;
  }
  .full-screen {
    display: none;
  }
  .huds-container {
    width: 100%;
    left: 0;
  }
  .instruction-box {
    width: 100%;
  }
  .info-bar {
    left: 0px;
    width: 100%;
    bottom: 8%;
  }
  /* .info-button {
    width: 150px;
    height: 75px;
    min-height: 50px;
    font-size: large;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    margin-bottom: auto;
  } */
}

@media (max-width: 700px) {
  .huds-container {
    justify-content: flex-start;
    height: 20%;
    bottom: 170px;
    /* height: fit-content; */
  }
  .instruction-box {
    width: 100%;
  }
  .info-bar {
    left: 0px;
    width: 100%;
    bottom: 11%;
  }
  .info-button {
    width: 100px;
    height: 70px;
    min-height: 50px;
    font-size: small;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    margin-bottom: auto;
  }
}

.btn.btn-success:disabled{
  background-color: #555555; /* Gray */
  border: none;
}

.selected-camera {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

@media (max-width: 480px) {
    .instruction-text {
        font-size: 13px;
        top: 12%;
    }
}

</style>
