<template>
    <h1 class='display-5' id='overview'>
        Overview
    </h1>
    <figure class="figure mx-auto d-block">
        <img src="./assets/wvs-icon.png" class="figure-img mx-auto d-block" alt="WVS Icon">
        <figcaption class="figure-caption text-center fst-italic">Figure 1. WVS Icon</figcaption>
    </figure>
    <p>
        The WVS can be accessed by finding the <i>WVS Icon</i> and double-clicking the icon with the left mouse button.
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="login-page">
        Login Page
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/login-page.png" class="figure-img mx-auto d-block" alt="Login Page"
        v-bind:style="{ width: '70%' }">
        <figcaption class="figure-caption text-center fst-italic">Figure 2. Login Page</figcaption>
    </figure>
    <p>
        Before accessing the WVS, user will need to choose their sign in method. The <i>Login Page</i> contains six options for 
        user to sign in:
        <ul>
            <li>Sign in with email</li>
            <li>Sign in with Google</li>
            <li>Sign in with Facebook</li>
            <li>Sign in with Twitter</li>
            <li>Sign in with GitHub</li>
            <li>Continue as guest</li>
        </ul>
        The first five options will redirect user to each corresponding platform for signing in, while <i>Continue as guest</i> 
        will directly bring user to the WVS' <i>Homepage</i>.
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="navigation">
        Navigation
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/navbar.png" class="figure-img mx-auto img-fluid d-block" alt="Navigation Bar" 
        v-bind:style="{ width: '35%' }">
        <figcaption class="figure-caption text-center fst-italic">Figure 3. Navigation Bar</figcaption>
    </figure>
    <p>
        The <i>Navigation Bar</i> contains features that will navigate users to:
        <ul>
            <li class="fst-italic">Homepage</li>
            <li class="fst-italic">Dashboard</li>
            <li class="fst-italic">Supervisor View</li>
            <li class="fst-italic">Settings</li>
            <li class="fst-italic">Logout</li>
        </ul>
        The <i>Navigation Bar</i> will be visible throughout the five mentioned pages above.
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="homepage">
        Homepage
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/home-page.png" class="figure-img mx-auto img-fluid d-block" alt="Homepage">
        <figcaption class="figure-caption text-center fst-italic">Figure 4. Homepage</figcaption>
    </figure>
    <p>User will be able to scan their vital sign in the <i>Homepage</i>. There will be <i>Disclaimer</i>, <i>Power Button</i>, <i>System Message</i>, 
        and <i>Copyright</i> in this page. The <i>Power Button</i> is used to power up the system's scanner while the <i>System Message</i> shows 
        the power status of the system.
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="dashboard">
        Dashboard
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/dashboard.png" class="figure-img mx-auto img-fluid d-block" alt="Dashboard">
        <figcaption class="figure-caption text-center fst-italic">Figure 5. Dashboard</figcaption>
    </figure>
    <p>The <i>Dashboard</i> will show the user's result history of their vital signs scanning. The <i>Username</i> of the user will be shown at the top right
         corner of the <i>Dashboard</i>. There will be <i>Charts</i> where user can see the overall result history within a day, a week, and a month. The <i>Vital 
         Signs</i> result details will be shown below the <i>Chart</i>.
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="supervisor-view">
        Supervisor View
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/supervisor-view.png" class="figure-img mx-auto img-fluid d-block" alt="Supervisor View">
        <figcaption class="figure-caption text-center fst-italic">Figure 6. Supervisor View</figcaption>
    </figure>
    <p>
        The <i>Supervisor View</i> will allow the supervisor of a team to view the team members' health data obtained from the scanning. The table will display
         the members information such as:
        <ul>
            <li>Name</li>
            <li>Department</li>
            <li>Wellness Score</li>
            <li>Heart Rate</li>
            <li>Breathing Rate</li>
            <li>Stress Rating</li>
            <li>Energy</li>
            <li>Productivity</li>
        </ul>
        The supervisor will also be able to see the time when the data was last updated.
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="settings">
        Settings
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/settings.png" class="figure-img mx-auto img-fluid d-block" alt="Settings Page">
        <figcaption class="figure-caption text-center fst-italic">Figure 7. Settings Page</figcaption>
    </figure>
    <p>
        The <i>Settings Page</i> contains a form which allows users to input their personal information such as:
        <ul>
            <li>Nickname</li>
            <li>Birthday</li>
            <li>Sex at birth</li>
            <li>Height</li>
            <li>Weight</li>
        </ul>
        There is a section for personal health information that the users can input for a more insightful scanning result such as:
        <ul>
            <li>Whether the user is smoking</li>
            <li>Whether the user has hypertension</li>
            <li>Whether the user is taking blood pressure medication</li>
            <li>Whether the user is diabetic</li>
        </ul>
        The user can also choose their preferred metric system to be used in the <i>Settings Page</i>.
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="logout">
        Logout
    </h2>
    <figure class="figure mx-auto d-block">
        <img src="./assets/logout.png" class="figure-img mx-auto img-fluid d-block" alt="Logout">
        <figcaption class="figure-caption text-center fst-italic">Figure 8. Logout</figcaption>
    </figure>
    <p>
        The user will be prompted to logout when clicking the <i>Logout</i> button from the <i>Navigation Bar</i>.
    </p>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style scoped>
p, li {
    text-align: justify;
}
</style>