<template>
  <div id="loading">
    <div id="loading-container">
      <img class="img" src="./assets/icon.gif" alt="loading..." width="100" />
      <p id="loading-text">{{ this.message }}</p>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
export default {
  name: "loading-screen",
  props: ['initialized', 'healthResult'], 
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  computed: {
    message() {
      let msg = this.t('message.loading_wait'); 
      if (!this.initialized) {
        msg = this.t('message.camera_preparation'); 
      } 
      else if (this.initialized && this.healthResult?.stage === 2 && this.healthResult?.health === undefined) {
        msg = this.t('message.wait_result');
      }
      return msg;
    },
  },
};
</script>

<style>
#loading {
  position: absolute;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding-left: 5%;
}

#loading-text {
  font-size: x-large;
  font-weight: 700;
  color: white;
  width: 75%;
  margin: auto;
}

#loading-container {
  position: relative;
  top: 43%;
  margin: auto;
}

.img {
  position: relative;
  margin: auto;
}
</style>

