<template>
  <dashboard v-if="currentUser" :currentUser="currentUser" @updateSelectedTag="updateSelectedTag"/>
  <side-bar v-if="selectedTag === ''"></side-bar>
</template>

<script>
import { b2cUserManager as newUserManager } from "vitals-database-plugin";
import "vitals-library-dashboard/dist/style.css";
import { Dashboard } from "vitals-library-dashboard";
import SideBar from "../sidebar/sidebar.vue";

export default {

  data() {
    const currentUser = newUserManager.currentUser
    return {
      currentUser,
      selectedTag: ''
    }


  },

  methods: {
    updateSelectedTag: function(newValue) {
      this.selectedTag = newValue
    }

  },

  components: {
    SideBar,
    Dashboard
  }
        
}

</script>