<template>
  <div class="content">
    <div class="container-full">
      <div class="row">
        <div class="col">
          <div id="multi-user-top-wrapper">
            <div class="title">PanopticAI Team</div>
            <div class="last-update">
              {{t('last_update')}} - <i>{{ date }}</i>
            </div>
          </div>
        </div>
        <div class="col userName"> {{t('user.supervisor')}} </div>
      </div>


      <div class="table-container">
      <table class="table">
        <thead class='header'>
          <tr>
            <th scope="col" style="width: 4%"></th>
            <th scope="col" style="width: 4%"></th>
            <th scope="col" style="width: 15%">{{t('user_info.name')}}</th>
            <th scope="col" style="width: 8%" class="text-middle">
              {{ t('user_info.department') }}
            </th>
            <th scope="col" style="width: 8%" class="text-middle">
              {{ t('vital_signs.general_wellness.title') }}
            </th>
            <th scope="col" style="width: 8%" class="text-middle">
              {{ t('vital_signs.heartRate.title') }}
            </th>
            <th scope="col" style="width: 8%" class="text-middle">
              {{ t('vital_signs.respiratoryRate.title') }}
            </th>
            <th scope="col" style="width: 8%" class="text-middle">
              {{ t('vital_signs.stress.title') }}
            </th>
            <th scope="col" style="width: 8%" class="text-middle">
              {{t('vital_signs.energy.title')}}
            </th>
            <th scope="col" style="width: 8%" class="text-middle">
              {{t('vital_signs.productivity.title')}}
            </th>
            <th scope="col" style="width: 8%" class="text-middle">
              ...
            </th>
            <th scope="col">
              <div id="multi-user-button-bar">
                <img class="button-icon" :src="getExpand" />
                <img class="button-icon" :src="getFilter" />
                <img class="button-icon" :src="getRefresh" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in data" :key="item.name + index">
          <tr class="align-middle">
            <td>
              <svg class="health-box" v-bind:class="item.health"></svg>
            </td>
            <td><img class="user-image" :src="item.image" /></td>
            <td>{{ item.name }}</td>
            <td class="text-middle">{{ item.department }}</td>
            <td class="text-middle">{{ item.general_wellness }}</td>
            <td class="text-middle">{{ item.heart_rate }}</td>
            <td class="text-middle">{{ item.respiratory_rate }}</td>
            <td class="text-middle">{{ item.stress }}</td>
            <td class="text-middle">{{ item.energy }}</td>
            <td class="text-middle">{{ item.productivity }}</td>
            <td class="text-middle">{{ item.more }}</td>
            <td><img class="button-icon expand-button" :src="getArrow" /></td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="copyright text-center"><p>{{ t('copyright') }}</p></div>
    </div>
  </div>
  <side-bar />
</template>

<script>
import { b2cUserManager as newUserManager } from "vitals-database-plugin";
import SideBar from "../sidebar/sidebar.vue";
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'

export default {
  setup() {
    const { t, locale } = useI18n()
    const router = useRouter()

    var currentUser = newUserManager.currentUser;
    if (!currentUser || !currentUser.userId) {
      router.push('/')
    }

    return {
      t, locale,
      data: ref([]),
      date: ref(""),
    };
  },
  components: {
    SideBar,
  },
  computed: {
    getExpand() {
      return require("./assets/expand-button.svg");
    },
    getFilter() {
      return require("./assets/filter-button.svg");
    },
    getRefresh() {
      return require("./assets/refresh-button.svg");
    },
    getArrow() {
      return require("./assets/down-arrow-icon.svg");
    },
    sampleImage() {
      return require("./assets/anonymous.png");
    },
    userName() {
      let currentUser = newUserManager.currentUser;
      if (!currentUser) {
        return "";
      }
      return currentUser.name;
    },
  },
  async mounted() {
    // console.log("mounting complete");

    // Generate some hardcoded data
    let displayArray = [];
    const listOfNames = [
      "Kyle",
      "Nick",
      "Teric",
      "Klyde",
      "Cherry",
      "Eve",
      "Jessica",
      "Aaron",
      "Kit",
      "Pierre",
      "Karen",
      "Noah",
      "Ismoil",
      "Kristian",
      "Sam",
      "Keith",
      "Mona",
      "Liam",
      "Chadwick",
    ];
    const listOfDepartments = [
      "Management",
      "Management",
      "Management",
      "Business",
      "Business",
      "Human Resource",
      "Human Resource",
      "Marketing",
      "Marketing",
      "Marketing",
      "Finance",
      "Finance",
      "IT",
      "IT",
      "IT",
      "IT",
      "INT",
      "INT",
      "HM",
    ];

    for (let i = 0; i < listOfNames.length; i++) {
      let rowObject = {
        health: "good",
        image: this.sampleImage,
        name: listOfNames[i],
        department: listOfDepartments[i],
        general_wellness: 90 + Math.floor(Math.random() * 10),
        heart_rate: 62 + Math.floor((Math.random() - 0.5) * 30),
        respiratory_rate: 14 + Math.floor((Math.random() - 0.5) * 5),
        stress: 2 + Math.round((Math.random() - 0.5) * 1 * 10) / 10,
        energy: 80 + Math.floor((Math.random() - 0.5) * 10),
        productivity: 80 + Math.floor((Math.random() - 0.5) * 10),
        more: "...",
      };
      displayArray.push(rowObject);
    }
    displayArray[4].health = "bad";
    displayArray[4].stress = 3.1;
    displayArray[4].general_wellness = 60;
    displayArray[4].energy = 50;
    displayArray[4].productivity = 30;
    displayArray[11].health = "bad";
    displayArray[11].stress = 3.2;
    displayArray[11].general_wellness = 59;
    displayArray[11].energy = 39;
    displayArray[11].productivity = 42;

    this.data = displayArray;
    this.date = new Date().toLocaleString();
  },
};
</script>

<style scoped>
.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #fafafa;
}

.container-full {
  padding-left: 41px;
  padding-top: 31px;
  padding-right: 41px;
  height: 100vh;
  overflow-y: scroll;

}

.text-middle {
  text-align: center;
}

#multi-user-button-bar > * {
  float: right;
  margin-left: 0.5rem;
}

#multi-user-top-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}

.last-update {
  vertical-align: middle;
  margin-left: 2rem;
  color: #666666;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}

.userName {
  text-align: right;
  margin-top: -1px;
  padding-right: 21px;

  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}

.user-image {
    max-width: 40px;
    opacity: 0.3;
}

.expand-button {
  float: right;
}

.health-box {
  align-self: center;
  height: 2rem;
  width: 2rem;
  border-radius: 10px;
}

.health-box.good {
  background: rgba(0, 255, 10, 0.5);
}

.health-box.bad {
  background: rgba(255, 0, 0, 0.5);
}

.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
}

.table tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: outset;
}
.table-container{
  overflow-x: auto;
}
@media (max-width: 768px) {
  .content {
    margin-bottom: 87px;
    padding-left: 0px;
  }
}
</style>
