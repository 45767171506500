<template>
  <div class="content">
    <div class="container">
      <!-- title -->
      <div class="row" style="margin-top:1%;">
        <div class="col-10">
          <div class="title">PanopticAI</div>
          <p id="app-version">{{ getAppVersion }}</p>
        </div>
        <!-- <div > {{ getAppVersion }} </div> -->
        <div class="col-2 locale">
          <locale-button id='locale'/>
        </div>
      </div>

      <!-- settings -->
      <div class="loadingMessage" v-if="!settingsLoaded"> {{ t('message.loading') }} ... </div>
      <div v-if="settingsLoaded">
        <div class="loadingMessage" style="text-decoration: underline;"> {{ t("message.fill_profile") }} </div>

        <!-- User Id and Profile Image -->
        <div class="row mb-6" style="padding-top: 1%; padding-bottom: 1%;">
          <div class="col-9">
            <div class="fs-10"> {{ formUserId }} </div>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <div style="float: right;display:grid;">
              <img id="profile-img" :src="this.form.profileImage">
              <button class="btn btn-primary btn-sm" onclick="document.getElementById('imgUploader').click()">Upload</button>
              <input type="file" ref="file" @change="handleImageUpload" accept="image/*" id="imgUploader" style="display:none">
            </div>
          </div>
        </div>
        
        <!-- Personal Information -->
        <hr/>
        <div class="row mb-3">
          <div class="fs-5 subheading">{{ t("user_info.personal_information") }}</div>
        </div>
        <form v-on:submit="saveSettings">
          <div class="row mb-3">
            <!-- Name -->
            <div class="col-6">
              <label for="nameInput" class="form-label">{{ t("user_info.name") }}</label>
              <input
                class="form-control"
                type="text"
                id="nameInput"
                v-model="this.form.name"
                @input="checkDisabled"
              />
            </div>
            <!-- Birthday -->
            <div class="col-6">
              <label for="birthdayInput" class="form-label">{{ t("user_info.birthday") }}</label>
              <input
                type="date"
                class="form-control"
                id="birthdayInput"
                v-model="this.form.birthday"
                @change="checkDisabled"
              />
            </div>
          </div>
          <!-- Gender -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="genderInput" class="form-label">
                {{ t("user_info.gender") }}
              </label>
            </div>
            <div class="col-6">
              <select
                class="form-select"
                id="genderInput"
                @change="checkDisabled"
                v-model="this.form.gender"
              >
                <option @change="checkDisabled" value=""></option>
                <option @change="checkDisabled" value="male"> {{ t("user_info.male") }} </option>
                <option @change="checkDisabled" value="female"> {{ t("user_info.female") }} </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <!-- Height -->
            <div class="col-6">
              <label for="heightInput" class="form-label">{{ t("user_info.height") }}</label>
              <input
                type="number"
                class="form-control"
                id="heightInput"
                v-model="this.form.height"
                @input="checkDisabled"
              />
            </div>
            <!-- Weight -->
            <div class="col-6">
              <label for="weightInput" class="form-label">{{ t("user_info.weight") }}</label>
              <input
                type="number"
                class="form-control"
                id="weightInput"
                v-model="this.form.weight"
                @input="checkDisabled"
              />
            </div>
          </div>            
          <!-- Waist Circumference -->
            <div class="row mb-3">
              <div class="col-6">
                <label for="waistInput" class="form-label">{{ t("user_info.waist_circumference") }}</label>
              </div>
              <div class="col-6">
                <input
                  type="number"
                  class="form-control"
                  id="waistInput"
                  v-model="form.waistCircumference"
                />
              </div>
            </div>
          <!-- Unit System -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="unitSystemSwitch" class="form-label">
                {{ t("user_info.unit_system") }}
              </label>
            </div>
            <div class="col-6">
              <div class="align-left">
                <div class="form-check form-switch" id="unitSystemSwitch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="unitSystemInput"
                    v-model="form.unitSystem"
                    @click="checkDisabled"
                  />
                  <label class="form-check-label" for="unitSystemInput">
                    {{ form.unitSystem
                      ? t("user_info.imperial")
                      : t("user_info.metric") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Countries -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="countriesInput" class="form-label">{{ t("user_info.country") }}</label>
            </div>
            <div class="col-6">
              <select
                class="form-select"
                id="countriesInput"
                @change="checkDisabled"
                v-model="this.form.countries"
              >
                <option @change="checkDisabled" value=""></option>
                <option @change="checkDisabled" value="No"> {{ t("option.no") }} </option>
                <option @change="checkDisabled" value="Hong Kong"> {{ t("user_info.countryOptions.hongkong") }} </option>
                <option @change="checkDisabled" value="China"> {{ t("user_info.countryOptions.china") }} </option>
              </select>
            </div>
          </div>

          <!-- Medical History -->
          <hr/>
          <div class="row mb-3">
            <div class="fs-5 subheading">{{ t("user_info.medical_history") }}</div>
          </div>
          <!-- Smoker -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="smokeSwitch" class="form-label">
                {{ t("user_info.smoking") }}
              </label>
            </div>
            <div class="col-6">
              <div class="align-left">
                <div class="form-check form-switch" id="smokeSwitch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="smokeInput"
                    v-model="form.smoker"
                    @click="checkDisabled"
                  />
                  <label class="form-check-label" for="smokeInput">
                    {{ form.smoker ? t("option.yes") : t("option.no") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Hypertension -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="hypertensionSwitch" class="form-label">
                {{ t("user_info.hypertension") }}
              </label>
            </div>
            <div class="col-6">
              <div class="align-left">
                <div class="form-check form-switch" id="hypertensionSwitch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="hypertensionInput"
                    v-model="form.hypertension"
                    @click="checkDisabled"
                  />
                  <label class="form-check-label" for="hypertensionInput">
                    {{ form.hypertension ? t("option.yes") : t("option.no") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- BP Medication -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="bpmedicationSwitch" class="form-label">
                {{ t("user_info.bp_medication") }}
              </label>
            </div>
            <div class="col-6">
              <div class="align-left">
                <div class="form-check form-switch" id="bpmedicationSwitch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="bpMedicationInput"
                    v-model="form.bloodPressureMedication"
                    @click="checkDisabled"
                  />
                  <label class="form-check-label" for="bpMedicationInput">{{ form.bloodPressureMedication ? t("option.yes") : t("option.no") }}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- Diabetic -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="diabeticInput" class="form-label">{{ t("user_info.diabetic") }}</label>
            </div>
            <div class="col-6">
              <select
                class="form-select"
                id="diabeticInput"
                @change="checkDisabled"
                v-model="this.form.diabetic"
              >
                <option @change="checkDisabled" value=""></option>
                <option @change="checkDisabled" value="No"> {{ t("option.no") }} </option>
                <option @change="checkDisabled" value="Type 1"> {{ t("user_info.type_1") }} </option>
                <option @change="checkDisabled" value="Type 2"> {{ t("user_info.type_2") }} </option>
              </select>
            </div>
          </div>
          <!-- heartDisease -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="heartDiseaseSwitch" class="form-label">
                {{ t("user_info.heart_disease") }}
              </label>
            </div>
            <div class="col-6">
              <div class="align-left">
                <div class="form-check form-switch" id="heartDiseaseSwitch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="heartDiseaseInput"
                    v-model="form.heartDisease"
                    @click="checkDisabled"
                  />
                  <label class="form-check-label" for="heartDiseaseInput">
                    {{ form.heartDisease ? t("option.yes") : t("option.no") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- depression -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="depressionSwitch" class="form-label">
                {{ t("user_info.depression") }}
              </label>
            </div>
            <div class="col-6">
              <div class="align-left">
                  <div class="form-check form-switch" id="depressionSwitch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="depressionInput"
                      v-model="form.depression"
                      @click="checkDisabled"
                    />
                    <label class="form-check-label" for="depressionInput">
                      {{ form.depression ? t("option.yes") : t("option.no") }}
                    </label>
                  </div>
              </div>
            </div>
          </div>

          <!-- button -->
          <hr />
          <div class="row mx-1">
            <button type="submit" class="btn btn-primary" :disabled="disabled">
              {{ t("button.continue") }}
            </button>
          </div>
        </form>
      </div>

      <!-- copyright message -->
      <div class="copyright text-center"><br /><br />{{ t("copyright") }}</div>
    </div>
  </div>
</template>

<script>
import { generateEmptyUserSettingsObject } from "vitals-database-plugin";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { b2cUserManager as newUserManager } from "vitals-database-plugin";
import localeButton from "../sidebar/locale-button.vue";

export default {
  setup() {
    const { t, locale } = useI18n();

    if (!newUserManager.currentUser) {
      const router = useRouter();
      router.push("/");
    }

    return { t, locale };
  },
  components: {
    localeButton,
  },
  data() {
    return {
      form: undefined,
      // form: generateEmptyUserSettingsObject(),
      disabled: true,
      debug: this.$root.debugMode,
    };
  },
  async mounted() {
    // setTimeout(async () => {
    //   if (newUserManager.currentUser && newUserManager.currentUser.userSettingsValid()){
    //     this.$router.push('/home')
    //   } else {
    //     this.loadForm();
    //   }
    // }, 200);

    let startTime = new Date();
    const loadInterval = setInterval(() => {
      if (newUserManager.currentUser && newUserManager.currentUser.userSettingsValid()){
        this.$router.push('/home');
        clearInterval(loadInterval);
      } else {
        this.loadForm();
      }
      let currentTime = new Date();
      if (this.form) {
        // console.log(this.form)
        this.checkDisabled();
        clearInterval(loadInterval);
      } else if ((currentTime - startTime) > 5000) {
        this.$router.push('/');
        clearInterval(loadInterval);
      }
    }, 300);
    
  },
  methods: {
    async saveSettings(e) {
      e.preventDefault();
      const response = await newUserManager.currentUser.setUserSettings(this.form)
      if ( response ) { alert(this.t("message.settings_updated")); }
      this.$router.push("/home");
    },
    checkDisabled() {
      let check = false;
      Object.keys(this.form).map((data) => {
        if (this.form[data] === null || this.form[data] === "") {
          check = true;
          return;
        }
      });
      this.disabled = check;
    },
    loadForm() {
      if (newUserManager.currentUser && newUserManager.currentUser.userSettings) {
        let emptyUserSettings = generateEmptyUserSettingsObject();
        Object.assign(emptyUserSettings, newUserManager.currentUser.userSettings)
        this.form = emptyUserSettings;
      }
      if (this.debug && this.form) {
        this.form.name = "DebugUser";
        this.form.gender = "male";
        this.form.birthday = "2002-02-20";
        this.form.height = 170;
        this.form.weight = 70;
        this.form.countries = "Hong Kong";
        this.form.waistCircumference = 70;
        this.form.diabetic = "No";
      }
    },
    handleImageUpload() {
      let fr = new FileReader();
      let file = (this.$refs.file && this.$refs.file.files[0]) ? this.$refs.file.files[0] : undefined;
      
      var myImg = undefined;
      if (file) {

        fr.onload = function() {
          let img = new Image();
          img.onload = function() {
            let canvas = document.createElement("canvas");
            canvas.width = 80;
            canvas.height = 80;
            canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height)
            myImg = canvas.toDataURL();
          }
          img.src = fr.result
        }

        fr.readAsDataURL(file);
      }

      let count = 5;
      const uploadTimer = setInterval(() => {
        if (myImg) {
          this.form.profileImage = myImg;
          clearInterval(uploadTimer);
        } 
        if (count < 0) {
          alert(`Failed to upload, please try again`, )
          clearInterval(uploadTimer)
        }
        count -= 1;
      }, 100);
    }
  },
  computed: {
    getAppVersion() {
      return this.$root.$data.appVersion;
    },
    formUserId() {
      if (this.form.userId) { return `${this.t('user_info.user_id')}: ${this.form.userId}`; }
      else { return "" }
    }, 
    settingsLoaded() {
      if (this.form) { return true }
      else { return false }
    }
  },
};
</script>

<style scoped>
.content {
  position: static;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.align-left {
  position: relative;
  float: left;
}
.loadingMessage {
  height: 50%;
  width: 100%;
  margin: auto auto;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
  font-size: xx-large;
  font-weight: bold;
  text-align: center;
}
.subheading {
  font-weight: bold;
}
#app-version {
  color: #888888;
  font-style: italic;
  font-weight: normal;
}
.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 20px;
  z-index: 0;
}
#locale {
  float: right;
}
#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  min-width:80px;
  max-width:80px;
}
</style>