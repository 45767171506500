<template>
  <sidebar-icon :inputIconPath="path" :selected="selected()" routerPath="/multi-user" />
</template>

<script>
import SidebarIcon from './sidebar-icon.vue';

export default {
    data() {
        return {
            width: window.innerWidth,
            path: undefined,
        };
    },
    mounted() {
      // load icon path
      this.getPath();
      
      window.addEventListener("resize", () => {
          this.width = window.innerWidth;
      });
    },
    methods: {
      selected() {
        return this.$route.fullPath == "/multi-user";
      },
      getPath() {
        this.path = this.selected() ? "multi_user_selected.svg" : "multi_user.svg";
      },
    },
    components: { SidebarIcon }
};
</script>

<style scoped>

</style>
