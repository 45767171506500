<template>
    <div class="information-box-container">
        <div 
            v-if="enable" 
            class="instruction-box"
        >
            {{ t("instruction.stay_still") }}
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default
  {
    name: 'information-box',
    props: {
        enable: { default: false, type: Boolean },
    },
    setup() {
        const { t, locale } = useI18n();
        return { t, locale }
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        isEnabled() {
            if (this.enable) {
                return "enabled"
            } else {
                return "disabled"
            }
        }
    },
}
</script>

<style scoped>
.information-box-container {
    position: fixed;
    left: calc(87px + 25%);
    width: calc(50% - 87px);
    height: 30%;
    top: 10%;
    /* background-color: rgba(0, 0, 0, 0.05); */
    border-radius: 30px;
}
.instruction-box {
  font-size: 48px;
  text-align: center;
  color: #ff3535;
  text-shadow: 1px 1px #dddddd;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

@media (max-width: 1044px) {
    .information-box-container {
        left: 87px;
        width: calc(100% - 87px);
    }
    .instruction-box {
        width: 100%;
        font-size: 42px;
    }
}

@media (max-width: 768px) {
    .information-box-container {
        left: 0px;
        width: 100%;
        top: 14%;
    }
    .instruction-box {
        width: 100%;
        font-size: 28px;
    }
}
</style>