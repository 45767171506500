<template>
  <sidebar-icon :inputIconPath="path" :selected="selected()" routerPath="/dashboard" />
</template>

<script>
import SidebarIcon from './sidebar-icon.vue';
export default {
    data() {
        return {
            width: window.innerWidth,
            path: undefined,
        };
    },
    mounted() {
      // load icon path
      this.getPath();

      window.addEventListener("resize", () => {
          this.width = window.innerWidth;
      });
    },
    methods: {
      selected() {
        return this.$route.fullPath == "/dashboard";
      },
      getPath() {
        this.path = this.selected() ? "chart_icon_selected.svg" : "chart_icon.svg";
      },
    },
    components: { SidebarIcon }
};
</script>

<style scoped>

</style>
