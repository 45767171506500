<template>
  <sidebar-icon :inputIconPath="path" :selected="selected()" routerPath="/help" />
</template>

<script>
import SidebarIcon from "./sidebar-icon.vue"

export default {
  data() {
      return {
          width: window.innerWidth,
          path: "help_icon_selected.svg",
      };
  },
  mounted() {
      // load icon path
      this.getPath(this.width)
      
      window.addEventListener("resize", () => {
          this.width = window.innerWidth;
      });
  },
  methods: {
      selected() {
          return this.$route.fullPath == "/help";
      }, 
      getPath() {
          this.path = this.selected() ? "help_icon_selected.svg" : "help_icon.svg";
      },
  },
  components: { SidebarIcon }
};
</script>

<style scoped>
</style>
