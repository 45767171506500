
<template>
    <div class="container"> 
        <div class="container-title">
            <div class="title">PanopticAI's Developer Page!</div>
            <div class="suptitle"> {{ title }} </div>
        </div>
        <div class="container-body">
            <div style="font-weight: bold;">Last {{ maxErrors }} error(s) captured @ </div>
            <div class="row">
                <div v-html="errorHtml"></div>
            </div>
            
        </div>
        <div class="container-footer">
            <br/>
            <button class="btn btn-danger btn-lg" v-on:click="clearError">Clear Log</button>
            <p>{{ t("copyright") }}</p>
        </div>
    </div>

    <side-bar/>
  </template>
  
<script>
import SideBar from "../sidebar/sidebar.vue";
import { useI18n } from "vue-i18n";
import Logger from "../../common/logger";


export default {
    name: "developer-page",
    setup() {
        const { t, locale } = useI18n();
        return {
            t, locale
        }
    },
    methods: {
        clearError() {
            Logger.clear()
            alert("Cleared Errors")
        }
    },
    computed: {
        title() {
            return `${this.$root.appTitle}`
        }, 
        maxErrors() {
            let errors = Logger.getErrors();
            if (errors) {
                return errors.datetime.length;
            } else {
                return 0;
            }
        },
        errorHtml() {
            let errors = Logger.getErrors(); 
            if (!errors) {
                return null
            }
            let htmlContents = "<div>"
            
            for (let i=0; i<errors.datetime.length; i++) {
                // console.log(errors.datetime[i], errors.message[i])
                htmlContents += `<div class="row my-1">
                    <div style="
                        font-size: large; 
                        font-weight:bold; 
                        font-style: italic;
                        color: lightgreen;
                        text-decoration: underline">
                        ${new Date(errors.datetime[i]).toString()}
                    </div>
                    <div>${errors.message[i]}</div>
                    </div>`
            }
            htmlContents += "</div>"
            return htmlContents
        },
        debugMessage() {
            /* You construct your debug message here */
            let error = Logger.getErrors()
            console.error(error)
            return error
        }
    },
    components: {
        SideBar,
    }
};
</script>

<style scoped>
.container {
    position: absolute;
    width: calc(100% - 87px);
    height: 100vh;
    left: 87px;
}
.container-title {
    position: relative;
    margin: auto auto;
    height: 10%;
    border-radius: 10px;
    background-color: rgba(253, 182, 182, 0.8);
}
.title {
    margin: auto auto;
    padding: 10px;
    font-size: xx-large;
    text-align: center;
    font-weight: bold;
}
.suptitle {
    margin: auto auto;
    font-size: x-large;
    text-align: center;
    font-style: italic;
    color: rgb(104, 104, 104)
}
.container-body {
    position: relative;
    margin: auto auto;
    padding: 10px;
    height: 80%;
    font-size: large;
    border-radius: 10px;
    background-color: rgba(255, 53, 53, 0.8);
    overflow: scroll;
}
.container-footer {
    position: relative;
    margin: auto auto;
    text-align: center;
    height: 5%;
}
.error-time {
    font-size: large;
    font-weight: bold;
    text-decoration: underline;
}
.error-message {
    font-style: italic;
    font-size: medium;
    color: rgb(166, 255, 170);
}

@media (max-width: 768px) {
    .container {
        position: absolute;
        width: 100%;
        left: 0px;
    }
    .container-title {
        position: relative;
        margin: auto auto;
        height: 20%;
        border-radius: 10px;
        background-color: rgba(253, 182, 182, 0.8);
    }
    .title {
        margin: auto auto;
        padding: 10px;
        font-size: x-large;
        text-align: center;
        font-weight: bold;
    }
    .suptitle {
        margin: auto auto;
        font-size: large;
        text-align: center;
        font-style: italic;
        color: rgb(104, 104, 104)
    }
    .container-body {
        position: relative;
        margin: auto auto;
        padding: 10px;
        height: 65%;
        font-size: x-small;
        border-radius: 10px;
        background-color: rgba(255, 53, 53, 0.8);
    }
    .container-footer {
        position: relative;
        margin: auto auto;
        text-align: center;
        height: 5%;
    }
    .error-time {
        font-size: large;
    }
    .error-message {
        font-size: x-small;
    }

}
</style>