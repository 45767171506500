<template>
  <HealthReport
    :currentUser="currentUser"
    :apiKey="apiKey"
    @submitRating="submitRating"
    @goToHome="goToHome"
  ></HealthReport>
  <SideBar />
  </template>
 
  <script>
  import { HealthReport } from 'health-report';
  import { useI18n } from 'vue-i18n';
  import { b2cUserManager as newUserManager } from "vitals-database-plugin";
  import SideBar from '../sidebar/sidebar.vue';
  import { useRouter } from 'vue-router';
  import Logger from '@/common/logger';
 
  export default {
    name: 'email-page',
    setup() {
      const { t, locale } = useI18n()
      const router = useRouter()
      const apiKey = 'CeVHP9OdUj54mr8btHU6L51VeDAj69pw91a7l5nP'
      // var currentUser = newUserManager.currentUser
      var currentUser = {
          userCategory: 'standard',
          ...newUserManager.currentUser,
      }
 
      if (!currentUser) {
        router.push('/');
      }
 
      return {
        t,
        locale,
        currentUser,
        apiKey,
      }
    },
    components: {
      SideBar,
      HealthReport,
    },
    created() {
      if (this.$root.$data.debugMode) {
        let currentUser = newUserManager.currentUser
          ? newUserManager.currentUser
          : null
        if (currentUser && !currentUser.lastHealth) {
          if (!currentUser.cloudInfo.attributes.email) {
            currentUser._genUserInfo()
            Logger.log(
              '%c<email-page debug>generated user info',
              'color:magenta'
            )
          }
          if (!currentUser.lastHealth) {
            currentUser._genLastHealth()
            Logger.log(
              '%c<email-page debug>generated last health',
              'color:magenta'
            )
          }
          Logger.log(
            '%cgenerated currentUser: ',
            'color:magenta',
            currentUser
          )
        }
      } else {
        if (!this.currentUser || !this.currentUser.lastVitalSigns) {
          this.$router.push('/');
        }
      }
    },
    methods: {
      submitRating(newRating) {
        if (newRating) {
          this.rating = newRating;
        }
 
        let currentUser = this.currentUser ? this.currentUser : null
        if (currentUser && this.currentUser) {
          currentUser.rating = this.rating;
          newUserManager.currentUser.uploadFeedback();
          Logger.log('rating: ', '', this.rating);
          alert(this.t('message.feedback'));
        }
      },
      goToHome() {
          this.$router.push("/"); 
      }
    },
  }
 </script>
