import "bootstrap";
import 'health-report/dist/style.css';
import 'vitals-library-dashboard/dist/style.css';
import { createApp } from "vue";
import Main from "./main.vue";
import DashboardPage from "./views/dashboard/dashboard-page.vue";
import MultiUserPage from "./views/multi-user/multi-user-page.vue";
import Settings from "./views/settings/settings.vue";
import DummyPage from "./views/dummy-page/dummy-page.vue";
import HelpPage from "./views/help/help-page.vue";
import HomeVueSDK from "./views/home-vue-sdk/Home.vue"

import EmailPage from "./views/email/email-page.vue";
import ProfileInput from "./views/login/profile-input.vue";
import { createI18n } from "vue-i18n";
import { locales as healthReportLocales } from "health-report";
import locales from "./locales.js";
import { createRouter, createWebHashHistory } from "vue-router";
import { locales as dashboardLocales } from "vitals-library-dashboard";
const dragscrollNext = require("vue-dragscroll");
import DeveloperPage from "./views/developer-page/developer-page.vue";
import LoginModule from "./views/login/aws-login.vue";

/* AWS config */
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

import _ from 'lodash';

let routes = [
  { path: "/", component: LoginModule },
  { path: "/home", component: HomeVueSDK },
  { path: "/dashboard", component: DashboardPage },
  { path: "/multi-user", component: MultiUserPage },
  { path: "/event", component: DummyPage },
  { path: "/email", component: EmailPage },
  { path: "/chat", component: DummyPage },
  { path: "/setting", component: Settings },
  { path: "/help", component: HelpPage },
  { path: "/input-profile", component: ProfileInput },
  { path: "/developer-page", component: DeveloperPage },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const combinedLocales = _.merge({}, healthReportLocales, dashboardLocales, locales)

const i18n = createI18n({
  legacy: false,
  locale: 'En', // set locale
  messages: combinedLocales, 
  warnHtmlInMessage: 'off',
})

const app = createApp(Main);
app.use(i18n);
app.use(router);
app.directive("dragscroll", dragscrollNext);
app.mount("#app");
