import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0ba8d059"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "condition-list" }
const _hoisted_3 = { class: "condition" }
const _hoisted_4 = { class: "condition" }
const _hoisted_5 = { class: "condition" }
const _hoisted_6 = { class: "condition" }
const _hoisted_7 = { class: "condition" }
const _hoisted_8 = {
  key: 0,
  class: "condition"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_condition_icon = _resolveComponent("condition-icon")!

  return (_ctx.conditions)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createVNode(_component_condition_icon, {
              class: "icon",
              fulfilled: _ctx.conditions.lighting
            }, null, 8, ["fulfilled"]),
            _createTextVNode(" " + _toDisplayString(_ctx.t('message.conditions.lighting')), 1)
          ]),
          _createElementVNode("p", _hoisted_4, [
            _createVNode(_component_condition_icon, {
              class: "icon",
              fulfilled: _ctx.conditions.distance
            }, null, 8, ["fulfilled"]),
            _createTextVNode(" " + _toDisplayString(_ctx.distance), 1)
          ]),
          _createElementVNode("p", _hoisted_5, [
            _createVNode(_component_condition_icon, {
              class: "icon",
              fulfilled: _ctx.conditions.centered
            }, null, 8, ["fulfilled"]),
            _createTextVNode(" " + _toDisplayString(_ctx.t('message.conditions.centered')), 1)
          ]),
          _createElementVNode("p", _hoisted_6, [
            _createVNode(_component_condition_icon, {
              class: "icon",
              fulfilled: _ctx.conditions.movement
            }, null, 8, ["fulfilled"]),
            _createTextVNode(" " + _toDisplayString(_ctx.t('message.conditions.movement')), 1)
          ]),
          _createElementVNode("p", _hoisted_7, [
            _createVNode(_component_condition_icon, {
              class: "icon",
              fulfilled: _ctx.conditions.serverReady
            }, null, 8, ["fulfilled"]),
            _createTextVNode(" " + _toDisplayString(_ctx.t('message.conditions.server_ready')), 1)
          ]),
          (!_ctx.conditions.frameRate)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                _createVNode(_component_condition_icon, {
                  class: "icon",
                  fulfilled: _ctx.conditions.frameRate
                }, null, 8, ["fulfilled"]),
                _createTextVNode(" " + _toDisplayString(_ctx.t('message.conditions.frame_rate')), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}