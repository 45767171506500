<template>
    <sidebar-icon :inputIconPath="path" :selected="selected()" routerPath="/home" />
</template>

<script>
import SidebarIcon from "./sidebar-icon.vue"

export default {
    data() {
        return {
            width: window.innerWidth,
            path: "home_icon_selected.svg",
        };
    },
    mounted() {
        // load icon path
        this.getPath(this.width)
        
        window.addEventListener("resize", () => {
            this.width = window.innerWidth;
        });
    },
    methods: {
        selected() {
            return this.$route.fullPath == "/home";
        }, 
        getPath() {
            this.path = this.selected() ? "home_icon_selected.svg" : "home_icon.svg";
        },
    },
    components: { SidebarIcon }
}
</script>

<style scoped>

</style>