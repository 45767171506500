<template>
  <sidebar-icon :inputIconPath="path" :selected="selected()" routerPath="/event" />
</template>

<script>
import SidebarIcon from './sidebar-icon.vue';

export default {
  data() {
      return {
          width: window.innerWidth,
          path: undefined,
      };
  },
  mounted() {
    // load icon path
    this.getPath();
    
    window.addEventListener("resize", () => {
        this.width = window.innerWidth;
    });
  },
  methods: {
    selected() {
      return this.$route.fullPath == "/event";
    },
    getPath() {
      this.path = this.selected() ? "event_icon_selected.svg" : "event_icon.svg";
    },
  },
  components: { SidebarIcon }
};
</script>

<style scoped>

</style>
