<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps">
    <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
      <VOnboardingStep>
        <div class="p-4 bg-white rounded shadow-p" :style="{ width: '350px' }">
          <div class="d-flex justify-content-between">
            <span
              v-if="step.content?.title"
              class="text-xl fs-4 leading-normal"
              >{{ step.content?.title }}</span
            >
            <button
              @click="
                exit();
                finished(true);
              "
              class="btn btn-light btn-sm"
            >
              x
            </button>
          </div>
          <p
            v-if="step.content?.description"
            class="text-sm text-gray-600 mt-2"
          >
            {{ step.content.description }}
          </p>
          <div class="d-flex mt-4 space-x-2" :class="{ 'h-6': index === 2 }">
            <button
              v-if="!isFirst"
              type="button"
              @click="previous"
              class="inline-flex flex-1 p-2 items-center justify-center border border-solid text-base font-medium rounded-full bg-transparent cursor-pointer border-gray-400 text-gray-800 hover:bg-gray-100"
            >
              Previous
            </button>
            <button
              @click="
                next();
                finished(isLast);
              "
              type="button"
              class="inline-flex flex-1 p-2 items-center justify-center border border-solid text-base font-medium rounded-full bg-transparent cursor-pointer border-transparent text-gray bg-indigo-600 hover:bg-indigo-700"
            >
              {{ isLast ? "Finish" : "Next" }}
            </button>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<script>
import { ref } from "vue";
import { VOnboardingWrapper, VOnboardingStep } from "v-onboarding";
import { b2cUserManager as newUserManager } from "vitals-database-plugin";
import { useI18n } from "vue-i18n";

// on-boarding steps for home-page

export default {
  name: "HomePageOnboard",
  setup() {
    const wrapper = ref(null);
    const { t, locale } = useI18n();
    // const steps = window.innerWidth> 768 ? stepsHomePage : stepsHomePage.slice(0, 3);
    return {
      t,
      locale,
      wrapper,
      // steps,
    };
  },
  async mounted() {
    // delay the onboarding and wait for the user data to be loaded
    let timeout = 5000; // 5 second
    setTimeout(async () => {
      let currentUser = newUserManager.currentUser;
      if (currentUser) {
        this.form = currentUser.userSettings;
        if (this.wrapper && this.form.firstTime.homepage) { this.wrapper.start(); }
      } 
    }, timeout);
  },
  computed: {
    steps() {
      const stepsHomePage = [
        {
          attachTo: {
            element: "#power-on-button",
          },
          content: {
            title: this.t("onboarding.homepage.step1.title"),
            description: this.t("onboarding.homepage.step1.description"),
          },
          options: {
            popper: {
              placement: "bottom-end",
            },
          },
        },
        {
          attachTo: {
            element: "#get-vs-button",
          },
          content: {
            title: this.t("onboarding.homepage.step2.title"),
            description: this.t("onboarding.homepage.step2.description"),
          },
        },
        {
          attachTo: {
            element: ".sidenav",
          },
          content: {
            title: this.t("onboarding.homepage.step3.title"),
            description: `${this.t("onboarding.homepage.step3.description")}${
              window.innerWidth > 768
                ? ""
                : " Scroll to the left to view more page navigation"
            }`,
          },
          options: {
            popper: {
              placement: window.innerWidth > 768 ? "right" : "top",
            },
          },
        },
        {
          attachTo: {
            element: "#home-button",
          },
          content: {
            title: this.t("onboarding.homepage.step4.title"),
            description: this.t("onboarding.homepage.step4.description"),
          },
          options: {
            popper: {
              placement: "right-end",
            },
          },
        },
        {
          attachTo: {
            element: "#chart-button",
          },
          content: {
            title: this.t("onboarding.homepage.step5.title"),
            description: this.t("onboarding.homepage.step5.description"),
          },
          options: {
            popper: {
              placement: "right-end",
            },
          },
        },
        {
          attachTo: {
            element: "#multi-user-button",
          },
          content: {
            title: this.t("onboarding.homepage.step6.title"),
            description: this.t("onboarding.homepage.step6.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
        {
          attachTo: {
            element: "#event-button",
          },
          content: {
            title: this.t("onboarding.homepage.step7.title"),
            description: this.t("onboarding.homepage.step7.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
        {
          attachTo: {
            element: "#email-button",
          },
          content: {
            title: this.t("onboarding.homepage.step8.title"),
            description: this.t("onboarding.homepage.step8.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
        {
          attachTo: {
            element: "#chat-button",
          },
          content: {
            title: this.t("onboarding.homepage.step9.title"),
            description: this.t("onboarding.homepage.step9.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
        {
          attachTo: {
            element: "#setting-button",
          },
          content: {
            title: this.t("onboarding.homepage.step10.title"),
            description: this.t("onboarding.homepage.step10.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
        {
          attachTo: {
            element: "#help-button",
          },
          content: {
            title: this.t("onboarding.homepage.step11.title"),
            description: this.t("onboarding.homepage.step11.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
        {
          attachTo: {
            element: "#logout-button",
          },
          content: {
            title: this.t("onboarding.homepage.step12.title"),
            description: this.t("onboarding.homepage.step12.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
        {
          attachTo: {
            element: "#locale-button",
          },
          content: {
            title: this.t("onboarding.homepage.step13.title"),
            description: this.t("onboarding.homepage.step13.description"),
          },
          options: {
            popper: {
              placement: "right-start",
            },
          },
        },
      ];

      return stepsHomePage;
    },
  },
  data() {
    return {
      form: undefined,
    };
  },
  methods: {
    async finished(isLast) {
      if (isLast) {
        let currentUser = newUserManager.currentUser;
        if (!currentUser) {
          return;
        } else {
          // read current user settings and update
          this.form = currentUser.userSettings;
          this.form.firstTime.homepage = false;
          await currentUser.setUserSettings(this.form);
          // console.log("finished onBoarding for Homepage", response)
        }
      }
    },
  },
  components: {
    VOnboardingWrapper,
    VOnboardingStep,
  },
};
</script>

<style></style>

<style scoped></style>
