<template>
    <sidebar-icon :inputIconPath="path" :selected="selected()" routerPath="/email" />
  </template>
  
  <script>
  import SidebarIcon from "./sidebar-icon.vue"
  
  export default {
    data() {
        return {
            width: window.innerWidth,
            path: "report_icon_selected.svg",
        };
    },
    mounted() {
        // load icon path
        this.getPath(this.width)
        
        window.addEventListener("resize", () => {
            this.width = window.innerWidth;
        });
    },
    methods: {
        selected() {
            return this.$route.fullPath == "/email";
        }, 
        getPath() {
            this.path = this.selected() ? "report_icon_selected.svg" : "report_icon.svg";
        },
    },
    components: { SidebarIcon }
  };
  </script>
  
  <style scoped>
  
  </style>
  