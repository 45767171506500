<template>
    <div id="canvas">
        <div class="system-message text-center"><p v-html="t('page.coming_soon')"/></div>
        <div class="copyright text-center"><p>{{ t('copyright') }}</p></div>
    </div>

    <side-bar />
</template>

<script>
import SideBar from '../sidebar/sidebar.vue';
import { useI18n } from 'vue-i18n';
import { b2cUserManager as newUserManager } from "vitals-database-plugin";
import { useRouter } from 'vue-router';

export default {
    setup() {
        const { t, locale } = useI18n();
        const router = useRouter();
        const currentUser = newUserManager.currentUser;
        if (!currentUser || !currentUser.userId) {
          router.push('/')
        }
        return {t, locale}
    },
    components: {
        SideBar
    }
}
</script>

<style>
body {
  background-color: lightgray;
}
</style>

<style scoped>
#canvas {
  position: absolute;
  left: 87px;
  height: 100%;
  width: calc(100% - 87px);
}
.system-message {
  margin-top: 10%;
  font-size: 30px;
  height: 70%;
}
.copyright {
  position: absolute;
  font-size: 14px;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 768px) {
  #canvas {
    width: 100%;
    left: 0px;
  }
  .copyright {
    bottom: 100px;
  }
}
</style>  